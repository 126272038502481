<lib-loading></lib-loading>

<ng-template modal>
  <lib-modal-component></lib-modal-component>
</ng-template>

<lib-toast aria-live="polite" aria-atomic="true"></lib-toast>

<header>
<app-header></app-header>
</header>

<main>
  <router-outlet></router-outlet>
</main>

<lib-footer [showDisclaimer]="false" class="footer"></lib-footer>

<ng-template #rebranding>
  <div class="card-body">
    <div class="row">
      <img class="mx-auto" src="/assets/images/2022-ignite/ignite-2.svg" height="150" alt="Ignite" />
    </div>
    <p class="h2 mt-3">We've launched our new name for <i>SmartrFit</i> - <b>Legal & General Ignite</b>.</p>
    <p class="h3 mt-3">Same great platform but with a new name and more products and lending types.</p>
  </div>
</ng-template>
