
  <lib-lending-type-buttons
    [value]="lendingType?.code"
    [filter]="['RES','BTL']"
    (lendingTypeSelect)="switchLendingType($event)"
  ></lib-lending-type-buttons>
<div class="floor-rate mt-5">
  <div class="col">
  <div>
    <div class="floor-rate-content">
      <div
        *ngFor="let floorRateForm of floorRateFormsForLendingType; index as index; trackBy: trackByFn"
        class="floor-rate-content-item me-5"
      >
        <h2>{{getRatePeriodText(floorRateForm.get('ratePeriod').value)}} Floor Rate</h2>

        <div class="form-group" [formGroup]="floorRateForm">
          <div class="mb-2">
            <lib-switch
              [showWarning]="false"
              [checked]="floorRateForm.get('floorRateBaseEnabled').value"
              (checkedChange)="onSwitch($event, 'floorRateBaseEnabled', floorRateForm)"
            >
              <span class="my-auto ms-2">Set Tracking and Discount Floor</span>
            </lib-switch>
          </div>
          <div class="mb-2">
            <p>Enter the value on which your floor rate margin is based</p>
            <p>For example entering 1 would mean a tracker product with a margin of 3.5% would have a floor rate of 4.5%</p>
          </div>

          <div class="mb-5">
            <div class="floor-rate-input-group">
              <label class="me-1 fw-bold" for="floorRateBase">Floor rate base</label>
              <input appDigitOnly
                class="form-control me-1 floor-rate-numberbox"
                type="number"
                formControlName="floorRateBase"
                placeholder="0"
                [class.is-invalid]="isFieldInvalid('floorRateBase', floorRateForm)"
              >
              <span>
                %
              </span>
            </div>
            <span class="d-flex text-danger" *ngIf="isFieldInvalid('floorRateBase', floorRateForm)">
              Floor rate base should be positive
            </span>
          </div>

          <div class="mb-2">
            <lib-switch
              [showWarning]="false"
              [checked]="floorRateForm.get('fixedFloorRateEnabled').value"
              (checkedChange)="onSwitch($event, 'fixedFloorRateEnabled', floorRateForm)"
            >
              <span class="my-auto ms-2">Set a fixed floor</span>
            </lib-switch>
          </div>

          <div class="mb-2">
            <p>
              Enter the fixed value to use for your floor rate.
            </p>
          </div>

          <div class="mb-2">
            <div class="floor-rate-input-group">
              <label class="me-1 fw-bold" for="fixedFloorRate">Fixed floor rate</label>
              <input appDigitOnly
                class="form-control me-1 floor-rate-numberbox"
                type="number"
                formControlName="fixedFloorRate"
                placeholder="0"
                [class.is-invalid]="isFieldInvalid('fixedFloorRate', floorRateForm)"
              >
              <span>
                %
              </span>
            </div>
            <span class="d-flex text-danger" *ngIf="isFieldInvalid('fixedFloorRate', floorRateForm)">
              Fixed floor rate should be positive
            </span>
          </div>
          <div class="d-flex flex-column mt-4" formArrayName="rateTypes">
            <p class="fw-bold floor-rate-input-group">Rate Type</p>
            <p class="mb-1">Enter the Rate Type to apply your floor rate against</p>
            <ng-container *ngFor="let type of rateTypes; let i=index">
              <div *ngIf="shouldDisplayRateType(type.rateType, floorRateForm.get('ratePeriod').value)" class="form-group my-0">
                <input
                  id="rate-type-{{index}}-{{i}}"
                  class="form-check-input me-1"
                  [formControlName]=" i"
                  type="checkbox"
                >
                <label class="form-check-label" for="rate-type-{{index}}-{{i}}">
                  {{rateTypes[i].name}}
                </label>
              </div>
            </ng-container>
            <div *ngIf="rateTypesInvalid(index)" class="text-danger">Please select a Rate Type to apply your floor rate against</div>
          </div>
        </div>
      </div>
    </div>

    <div class="floor-rate-footer sticky-bottom">
      <button
        class="btn btn-2022--secondary floor-rate-button"
        (click)="submit()"
        [disabled]="!saveButtonEnabled"
      >
        Save
      </button>
    </div>
  </div>
</div>
